import { type MetaFunction } from '@remix-run/cloudflare';

import CategoriesMarketCapQuotationChart from './component/CategoriesMarketCapQuotationChart';
import CategoriesReturnsList from './component/CategoriesReturnsList';

export const meta: MetaFunction = () => {
  return [
    { title: 'odds guidance' },
    {
      name: 'description',
      content: 'odds guidance',
    },
  ];
};

export default function Index() {
  return (
    <div style={{ fontFamily: 'system-ui, sans-serif', lineHeight: '1.8' }}>
      <div className="flex w-full items-center justify-center h-full">
        <main className="flex items-center justify-center flex-col gap-5 h-full w-full pt-9">
          <h1 className="text-2xl font-bold">Welcome to odds-guidance</h1>
          <div className="max-w-[1024px] w-full">
            <CategoriesMarketCapQuotationChart />
            <CategoriesReturnsList />
          </div>
        </main>
      </div>
    </div>
  );
}
