import { type ComponentProps, type FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { Tab, Tabs } from '@nextui-org/react';

import useFetchCategories from '~/hook/query/useFetchCategories';
import useFetchCategoriesMarketCapQuotation from '~/hook/query/useFetchCategoriesMarketCapQuotation';

const CategoriesMarketCapQuotationChart: FC = () => {
  const [interval, setInterval] = useState<'1day' | '1week'>('1week');
  const [queryCategories, setQueryCategories] = useState<number[]>([]);
  const { data: categories } = useFetchCategories();
  const { data: quotation } = useFetchCategoriesMarketCapQuotation(interval, queryCategories);

  useEffect(() => {
    if (!categories) return;

    const rootCategoriesId = categories.filter((c) => c.parentId === null).map((c) => c.id);
    setQueryCategories(rootCategoriesId);
  }, [categories]);

  const data: ComponentProps<typeof Line>['data'] = {
    labels: (quotation?.[0]?.quotes || [])
      .slice()
      .reverse()
      .map((quote) => quote.datetime),
    datasets:
      quotation?.map((c) => ({
        label: c.categoryName,
        data: c.quotes
          .slice()
          .reverse()
          .map((q) => q.marketCap),
        borderWidth: 1,
      })) || [],
  };

  return (
    <div className="flex w-full flex-col">
      <Line
        options={{
          responsive: true,
        }}
        data={data}
      />
      <div className="flex w-full justify-center mt-4">
        <Tabs selectedKey={interval} size="md" onSelectionChange={setInterval as never}>
          <Tab key="1day" title="1day" />
          <Tab key="1week" title="1week" />
        </Tabs>
      </div>
    </div>
  );
};

export default CategoriesMarketCapQuotationChart;
