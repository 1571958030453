import { type FC, useEffect, useState } from 'react';

import { Listbox, ListboxItem } from '@nextui-org/react';
import { NavLink } from '@remix-run/react';

import ReturnCard from '~/component/card/ReturnCard';
import useFetchCategories from '~/hook/query/useFetchCategories';
import useFetchCategoriesReturns from '~/hook/query/useFetchCategoriesReturns';

const CategoriesReturnsList: FC = () => {
  const [queryCategories, setQueryCategories] = useState<number[]>([]);
  const { data: categories } = useFetchCategories();
  const { data: categoriesReturns } = useFetchCategoriesReturns([1, 3, 6, 12], queryCategories);

  useEffect(() => {
    if (!categories) return;

    const rootCategoriesId = categories.filter((c) => c.parentId === null).map((c) => c.id);
    setQueryCategories(rootCategoriesId);
  }, [categories]);

  return (
    <div className="w-full max-w-[540px] px-1 py-2 mx-auto">
      <Listbox>
        {categoriesReturns?.map((c) => (
          <ListboxItem key={c.categoryName}>
            <NavLink to={`/category/${c.categoryId}`}>
              <ReturnCard returns={c.returns} targetDate={c.targetDate} title={c.categoryName} />
            </NavLink>
          </ListboxItem>
        )) || []}
      </Listbox>
    </div>
  );
};

export default CategoriesReturnsList;
